import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import { GoAlert } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import {
	clearCartItem,
	selectCartItems,
	selectTotal,
} from "../slices/appSlices";
import { db } from "../firebase";
import Layout from "../components/shared/Layout";
import { UserContext } from "../context/user-context";
import { AUTHORIZED_ID } from "../constant";
import { roundTo2Dec } from "../utils/roundTo2Dec";
import OrderSum from "../components/shared/OrderSum";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Heading from "../components/shared/Heading";

const Success = () => {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const cartItems = useSelector(selectCartItems);
	const totalPriceFromCart = useSelector(selectTotal);
	const userAddress = localStorage.getItem("address");
	const payload = localStorage.getItem("payload");
	const userEmail = user?.email || localStorage.getItem("altEmail");
	const orderNo = localStorage.getItem("orderno");
	const finalPriceToPay = roundTo2Dec(+localStorage.getItem("finalPriceToPay"));
	const userAddss = localStorage.getItem("address");
	const finalPriceToPayObject = JSON.parse(
		localStorage.getItem("finalPriceToPayObject")
	);
	const phone = JSON.parse(localStorage.getItem("phone"));
	const tax = JSON.parse(
		localStorage.getItem("finalPriceToPayObject")
	)?.taxInDollar;
	const finalTax = finalPriceToPayObject?.taxInDollar;
	const finalShipCost = finalPriceToPayObject?.shipCostInDollar;
	const PriceInDollar = finalPriceToPayObject?.priceInDollar;
	const discountPrice = Number(localStorage.getItem("discountPrice"));
	const shippingCost = JSON.parse(localStorage.getItem("shippingCost"));
	const discountedAmount = roundTo2Dec(totalPriceFromCart - discountPrice);
	const shipMethod = localStorage.getItem("ship");
	const payMethod = "card";

	const myCurrentDate = new Date();
	const timestampA = myCurrentDate.setDate(myCurrentDate.getDate() + 14);
	const timestampB = myCurrentDate.setDate(myCurrentDate.getDate() + 7);
	const futureDate1 = new Date(timestampA).toDateString();
	const futureDate2 = new Date(timestampB).toDateString();

	let nameArray = [];
	let quantity = 0;
	let id = [];

	// eslint-disable-next-line array-callback-return
	cartItems.map((item) => {
		nameArray.push(
			`${item.quantity} ${item.name} - ${
				item.hairLength ? `${item.hairLength}inches` : ""
			} ..... $${item.price}`
		);
		quantity += item.quantity;
		id.push(`${item._id}-${item.name}`);
	});

	React.useEffect(() => {
		let __timeOut;
		clearTimeout(__timeOut);
		// Send a purchase mail to client
		const messageParams = {
			name: (user && user?.displayName) || userEmail,
			id: id.join(", "),
			orderNo: orderNo,
			date: new Date().toDateString(),
			product: nameArray.join(", "),
			price: PriceInDollar,
			quantity: quantity,
			discount: (discountPrice && discountedAmount) || "00.00",
			subtotal: PriceInDollar,
			shipping: shipMethod === "ship" ? finalShipCost : "0",
			shipMethod: shipMethod,
			tax: finalTax,
			total: finalPriceToPay,
			address: userAddress,
			client: userEmail,
			payMethod: payMethod,
			estShipTime: `${futureDate1} - ${futureDate2}`,
		};

		const SendClientSuccessfulPurchaseEmail = () => {
			emailjs
				.send(
					process.env.REACT_APP_EMAILJS_SERVICE, // YOUR_SERVICE_ID
					process.env.REACT_APP_EMAILJS_TEMPLATE, // YOUR_TEMPLATE_ID
					messageParams,
					process.env.REACT_APP_EMAILJS_PUBLIC_KEY // YOUR_PUBLIC_KEY
				)
				.then((res) => {})
				.catch((err) => console.log(err));
		};

		__timeOut = setTimeout(() => {
			SendClientSuccessfulPurchaseEmail();
		}, 1000);

		userEmail &&
			cartItems.length !== 0 &&
			payload &&
			// eslint-disable-next-line array-callback-return
			cartItems.map((item) => {
				// shopping path
				db.collection("purchased")
					.doc(`${userEmail}/`)
					.collection("shoppings")
					.add({
						id: item?._id + orderNo,
						title: item?.name,
						description: item?.description,
						color: item?.colorOption ? item?.colorOption : "",
						quantity: item?.quantity,
						price: item?.price,
						address: userAddress,
						customer: (user && user?.displayName) || userEmail,
						email: userEmail,
						length: item?.hairLength ? item?.hairLength : "",
						orderNo: orderNo,
						timestamp: new Date().valueOf(),
						image: item?.image ? item?.image : "",
						shipMethod: shipMethod,
					})
					.then(() => {
						console.log(`SUCCESSFULL`);
					})
					.catch((error) => console.log("Error" + error.message));

				// admin path
				db.collection("admin")
					.doc(`${AUTHORIZED_ID.id_one}/`)
					.collection("all-purchased")
					.add({
						id: item?._id + orderNo,
						title: item?.name,
						description: item?.description,
						color: item?.colorOption ? item?.colorOption : "",
						quantity: item?.quantity,
						price: item?.price,
						address: userAddress,
						customer: (user && user?.displayName) || userEmail,
						email: userEmail,
						length: item?.hairLength ? item?.hairLength : "",
						orderNo: orderNo,
						timestamp: new Date().valueOf(),
						image: item?.image ? item?.image : "",
						shipMethod: shipMethod,
					})
					.then(() => {
						console.log(`SUCCESSFULL`);
					})
					.catch((error) => console.log("Error" + error.message));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBackToShopping = () => {
		dispatch(clearCartItem());
		localStorage.removeItem("payload");
		localStorage.removeItem("address");
		localStorage.removeItem("altEmail");
		localStorage.removeItem("ship");
		localStorage.removeItem("orderno");
		localStorage.removeItem("finalPriceToPayObject");
		localStorage.removeItem("finalPriceToPay");
		localStorage.removeItem("phone");
		navigate("/");
	};

	const customerName = userEmail
		? `Thank you ${userEmail}`
		: user
		? `Thank you ${user?.displayName}`
		: "Thank you!";

	return (
		<>
			<Helmet>
				<title>PVG: Success-page</title>
			</Helmet>
			<Layout>
				{payload ? (
					<div className="pt-[80px] md:pt-[50px] flex flex-col items-left text-neutral-600 font-thin home">
						<div className="flex justify-center w-full mx-auto">
							{/* <img src={logo} alt="" className="w-[100px]" /> */}
						</div>
						<div className="border-t-[1px] border-b-[1px] border-yellow-400 w-full mt-[-15px]">
							<OrderSum
								isBorder={false}
								cartItems={cartItems}
								discountPrice={discountPrice}
								total={totalPriceFromCart}
								isSuccess={true}
								shipCost={shippingCost?.cost}
								notShippingText={shipMethod}
								tax={tax}
							/>
						</div>
						<div className={`px-5 pt-5 bg-neutral-200`}>
							<div className="w-full lg:w-[60%] xl:w-[50%] mx-auto">
								<div className="flex items-center mb-1 text-md 0">
									<AiOutlineCheckCircle className="mr-1" color="green" />{" "}
									<Heading children={customerName} size3={true} />
								</div>
								<p className="text-xs">{`Order: ${orderNo}`}</p>
								<div className="mt-5 font-light text-xs border-[1px] border-neutral-300 rounded-[6px] p-3">
									<Heading
										children="Your order is confirmed"
										size2={true}
										isBold2={true}
									/>
									<p>You'll receive an email when your order is ready.</p>
								</div>
								<div className="mt-3 font-light text-xs border-[1px] border-neutral-300 rounded-[6px] p-3">
									<div className="mb-5">
										<Heading
											children="Customer information"
											size2={true}
											isBold2={true}
										/>
									</div>
									<div className="grid grid-cols-1 gap-2 md:grid-cols-2">
										<div className="mb-5">
											<Heading
												children="Contact information"
												size1={true}
												isBold2={true}
											/>
											<p className="text-[10px]">{user?.email || userEmail}</p>
										</div>
										<div className="mb-5">
											<Heading
												children="Payment method"
												size1={true}
												isBold2={true}
											/>
											<p className="text-[10px]">
												{payMethod} - ${finalPriceToPay}
											</p>
										</div>
										<div className="mb-5">
											<Heading
												children="Shipping address"
												size1={true}
												isBold2={true}
											/>
											<p className="text-[10px]">{user?.name || userEmail}</p>
											<p className="text-[10px] w-[35%]">{userAddss}</p>
											<p className="text-[10px] w-[35%]">
												{phone.countryCode - phone.phone}
											</p>
										</div>
										<div className="mb-5">
											<Heading
												children="Billing address"
												size1={true}
												isBold2={true}
											/>
											<p className="text-[10px]">{user?.name || userEmail}</p>
											<p className="text-[10px] w-[35%]">{userAddss}</p>
											<p className="text-[10px] w-[35%]">
												{phone.countryCode - phone.phone}
											</p>
										</div>
										<div className="mb-5">
											<Heading
												children="Shipping method"
												size1={true}
												isBold2={true}
											/>
											<p className="text-[10px]">
												{shipMethod === "ship"
													? "Standard Shipping"
													: "Pick up"}
											</p>
										</div>
									</div>
								</div>
								<div
									onClick={handleBackToShopping}
									className="my-10 w-[100%] bg-neutral-900 text-center text-xs text-white py-4 cursor-pointer rounded-[6px] md:hover:bg-neutral-300 md:hover:text-neutral-900 ease-in duration-300">
									<span>Continue shopping</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="flex flex-col items-center justify-center my-10 font-bold text-orange-700 uppercase">
						<GoAlert className="mb-5 mr-5 text-3xl" />
						You do not have any transactions
					</div>
				)}
			</Layout>
		</>
	);
};

export default Success;
