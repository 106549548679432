import React from "react";
import {
	BsChevronRight,
	BsFillLockFill,
	BsFillUnlockFill,
} from "react-icons/bs";

const ThirtyDaysCalendar = ({ condition, text, func }) => {
	const [showSet, setShowSet] = React.useState(false);

	return (
		<div className="h-[36px] w-full flex items-center justify-between border">
			<div
				onClick={() => setShowSet(!showSet)}
				className="flex items-center justify-center w-full h-full cursor-pointer text-black/60">
				<div className="mx-2">
					{condition ? (
						<BsFillLockFill size={20} className="text-orange-800" />
					) : (
						<BsFillUnlockFill size={20} className="text-green-800" />
					)}
				</div>
				<p>{text}</p>
			</div>
			<div
				onClick={func}
				className={`${
					showSet ? "inline-block" : "hidden"
				} h-full border-l text-white w-[50px] flex items-center justify-center cursor-pointer hover:bg-neutral-700  ease-in duration-300`}>
				<BsChevronRight className="w-full h-full duration-300 ease-in hover:translate-x-1 " />
			</div>
		</div>
	);
};

export default ThirtyDaysCalendar;
