import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Terms from "./pages/terms-and-conditions";
import NotFound from "./pages/error";
import Checkout from "./pages/checkout";
import Management from "./pages/management";
import Login from "./pages/login";
import Register from "./pages/register";
import Canceled from "./pages/canceled";
import Success from "./pages/success";
import BookingsPage from "./pages/bookingsPage";
import Dashboard from "./pages/dashboard";
import SingleBookPage from "./pages/singleBookPage";

function App() {
	return (
		<Routes>
			<Route exact path="/checkout/:userId" element={<Checkout />} />
			<Route exact path="/about-us/:pvg" element={<About />} />
			<Route exact path="/contact-us/:pvg" element={<Contact />} />
			<Route exact path="/terms-and-conditions/:pvg" element={<Terms />} />
			<Route exact path="/success" element={<Success />} />
			<Route exact path="/canceled" element={<Canceled />} />
			<Route exact path="/management" element={<Management />} />
			<Route exact path="/sign-in" element={<Login />} />
			<Route exact path="/sign-up" element={<Register />} />
			<Route exact path="/product/:productId" element={<Home />} />
			<Route
				exact
				path="/:country/:state/bookings"
				element={<BookingsPage />}
			/>
			<Route exact path="/:country/dashboard" element={<Dashboard />} />
			<Route exact path="/customer/:bookId" element={<SingleBookPage />} />

			<Route exact path="*" element={<NotFound />} />
			<Route exact path="/" element={<Home />} />
		</Routes>
	);
}

export default App;
