/* eslint-disable array-callback-return */
import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MdKeyboardBackspace } from "react-icons/md";
import Layout from "../components/shared/Layout";
import CalendarCompoent from "../components/calendarPage/CalendarCompoent";
import { RiHome5Fill } from "react-icons/ri";
import Form from "../components/shared/Form";

const Dashboard = () => {
	const { REACT_APP_ADMIN_CODE } = process.env;
	const [adminCodeValue, setAdminCodeValue] = React.useState("");
	const [selectedTab, setSelectedTab] = React.useState("calendar");

	const tabList = ["calendar", "add service"];

	return (
		<div className="relative bg-neutral-200">
			<Helmet>
				<title>Dashboard</title>
			</Helmet>
			<Layout>
				<div className="bg-neutral-300 pt-[60px] pb-[80px]">
					<div className="relative w-full md:w-[80%] lg:w-[70%] 2xl:w-[60%] mx-auto">
						{adminCodeValue !== REACT_APP_ADMIN_CODE ? (
							<div className="px-5 text-orange-800 md:px-0">
								<p className="text-2xl">Unauthorized Path</p>
								<p>
									If you know you are authorized to access this path, provide
									the admin credentials below !!!
								</p>
								<p className="my-10">
									<label for="admin" className="mr-5">
										Admin credentials
									</label>
									<input
										value={adminCodeValue}
										onChange={(e) => setAdminCodeValue(e.target.value)}
									/>
								</p>
								<Link to="/" className="flex items-center my-10 w-fit">
									<MdKeyboardBackspace className="text-yellow-900" />
									<span className="text-sm italic text-yellow-900 navStyleChild">
										Back to home
									</span>
								</Link>
							</div>
						) : (
							<React.Fragment>
								<div className="relative w-[100%] flex flex-row gap-10 justify-center mx-auto pb-5 border-b-neutral-400 border-b-[1px]">
									<Link
										to="/"
										className="border-[1px] absolute -top-2 left-5 sm:left-0 md:left-0 shadow-lg w-[40px] h-[40px] text-orange-800 rounded-full p-2 md:hover:bg-orange-900 md:hover:text-white hover:w-[40px] hover:p-3 hover:h-[40px] ease-in duration-300">
										<RiHome5Fill className="w-[100%] h-[100%]" />
									</Link>
									{tabList.map((tab, idx) => (
										<span
											onClick={() => setSelectedTab(tab)}
											key={idx}
											className={`px-3 py-1 text-[10px] uppercase  rounded-full cursor-pointer ${
												tab === selectedTab
													? "bg-orange-400 text-orange-50"
													: "bg-orange-100 text-orange-800"
											}`}>
											{tab}
										</span>
									))}
								</div>
								{selectedTab === "calendar" && <CalendarCompoent />}
								{selectedTab === "add service" && <Form />}
							</React.Fragment>
						)}
					</div>
				</div>
			</Layout>
		</div>
	);
};

export default Dashboard;
