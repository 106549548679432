/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { GoAlert } from "react-icons/go";
import { clearCartItem, selectCartItems } from "../../slices/appSlices";
import { useSelector, useDispatch } from "react-redux";
import { SiMastercard } from "react-icons/si";
import { RiVisaLine } from "react-icons/ri";
import { SiAmericanexpress } from "react-icons/si";
import { UserContext } from "../../context/user-context";
import { SHIPPING_COST } from "../../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ValidateEmail } from "../../utils/ValidateEmail";
import { countries } from "../../country";
import { roundTo2Dec } from "../../utils/roundTo2Dec";

const CheckoutForm = ({ total, itemCount }) => {
	const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const cartItems = useSelector(selectCartItems);
	const [email, setEmail] = React.useState("");
	const [succeeded, setSucceeded] = React.useState(false);
	const [_error, set_Error] = React.useState(null);
	const [processing, setProcessing] = React.useState("");
	const [disabled, setDisabled] = React.useState(true);
	const [clientSecret, setClientSecret] = React.useState("");
	const [shipMethod, setShipMethod] = React.useState("");
	const [allowproceed, setAllowProceed] = React.useState(false); //CHANGE BACK TO FALSE

	const shipOptions = ["ship", "pick-up"];
	const [address, setAddress] = React.useState({
		street: "",
		city: "",
		province: "",
		postalcode: "",
		country: {
			countryCode: "",
			countryName: "",
			currencyCode: "",
		},
	});
	const [phone, setPhone] = React.useState({
		phone: "",
		countryCode: "",
	});
	const [shippingCost, setShippingCost] = React.useState({
		country: "",
		cost: "",
	});
	const [error, setError] = React.useState(false);
	const [shipError, setShipError] = React.useState(false);
	const dispatch = useDispatch();
	const { user } = useContext(UserContext);
	const inputOnchangeHandler = (e, isCountry) => {
		let value = e.target.value.toLowerCase();

		if (isCountry) {
			countries.find((country) => {
				if (country.countryName.toLowerCase() === value) {
					setAddress({
						...address,
						[e.target.name]: {
							countryCode: country.countryCode,
							countryName: country.countryName,
							currencyCode: country.currencyCode,
						},
					});
				}
			});
		} else {
			setAddress({ ...address, [e.target.name]: value });
		}
	};

	// Submit address
	const handleSubmitAddress = () => {
		const shippingAd = `${address.street}, ${address.city}. ${address.province}. ${address.postalcode}. ${address.country.countryName}`;

		if (shipError) {
			setShipError(false);
		}

		if (!user || !email) {
			setError(true);
		}
		if (!shipMethod) {
			setShipError(true);
		}
		if (
			shipMethod &&
			((user &&
				address?.city &&
				address?.street &&
				address?.province &&
				address?.postalcode &&
				address?.country.countryName) ||
				(email &&
					ValidateEmail(email) &&
					address?.street &&
					address?.city &&
					address?.province &&
					address?.postalcode &&
					address?.country.countryName))
		) {
			localStorage.setItem("address", shippingAd);
			localStorage.setItem("phone", JSON.stringify(phone));
			localStorage.setItem("ship", shipMethod);
			localStorage.setItem("altEmail", email);
			localStorage.setItem(
				"orderno",
				`PVG${Math.random().toString(36).slice(2)}`
			);
			setAllowProceed(true);
			setAddress({
				street: "",
				city: "",
				province: "",
				postalcode: "",
				country: "",
			});
			setPhone({
				phone: "",
				countryCode: "",
			});

			setError(false);
			setEmail("");
		}
		if (SHIPPING_COST[address.country.countryCode]) {
			setShippingCost({
				country: address.country.countryName,
				cost: SHIPPING_COST[address.country.countryCode],
			});
		} else {
			setShippingCost({
				country: address.country.countryName,
				cost: 20,
			});
		}
	};

	const cardStyle = {
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "12px",
				"::placeholder": {
					color: "#32325d",
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	const shipping_fee =
		shipMethod === "ship" ? Math.floor(shippingCost.cost * 100) : 0;
	const taxPercentage = total * +process.env.REACT_APP_TAX_PERCENT;
	const tax = Math.floor(taxPercentage * 100);
	const totalPrice = Math.floor(total * 100);
	const shipCostInCent = roundTo2Dec(shippingCost?.cost * 100);
	const shipCostInDollar = shipCostInCent / 100;
	const priceInDollar = total;
	const taxInDollar = roundTo2Dec(total * +process.env.REACT_APP_TAX_PERCENT);

	const finalPriceToPay =
		shipMethod === "ship"
			? (shipCostInDollar + priceInDollar + taxInDollar).toFixed(2)
			: (priceInDollar + taxInDollar).toFixed(2);

	localStorage.setItem("finalPriceToPay", finalPriceToPay);
	localStorage.setItem(
		"finalPriceToPayObject",
		JSON.stringify({
			priceInDollar: priceInDollar,
			shipCostInDollar: shipCostInDollar,
			taxInDollar: taxInDollar,
		})
	);

	const createPaymentIntent = async () => {
		try {
			const { data } = await axios.post(
				"/.netlify/functions/create-payment-intent",
				JSON.stringify({ cartItems, shipping_fee, totalPrice, tax })
			);

			setClientSecret(data.clientSecret.split("'")?.[0]);
		} catch (error) {
			set_Error(
				error?.response?.data
					? "Please contact PVG International-S Admin..."
					: ""
			);
		}
	};

	React.useEffect(() => {
		let mountedRef = true;
		if (!mountedRef) return null;
		if (shipping_fee >= 0) {
			createPaymentIntent();
		}

		return () => {
			mountedRef = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shipping_fee]);

	const handleChange = async (event) => {
		setDisabled(event.empty);
		set_Error(event.error ? event.error.message : "");
	};

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		setProcessing(true);

		let subTimout;
		clearTimeout(subTimout);

		if (clientSecret) {
			const payload = await stripe.confirmCardPayment(clientSecret, {
				payment_method: {
					card: elements.getElement(CardElement),
				},
			});

			localStorage.setItem("payload", payload?.paymentIntent?.client_secret);

			if (payload.error) {
				set_Error(`Payment failed ${payload.error.message}`);
				setProcessing(false);
				subTimout = setTimeout(() => {
					navigate("/canceled");
				}, 5000);
			} else {
				set_Error(null);
				setProcessing(false);
				setSucceeded(true);
				subTimout = setTimeout(() => {
					payload?.paymentIntent?.client_secret && navigate("/success");
				}, 5000);
			}
		}
	};

	return (
		<div>
			<div className="max-w-[100%] md:max-w-[70%] mx-auto">
				<div
					className={`mb-5 border-[1px] border-neutral-400 px-5 py-3 rounded-lg flex items-center justify-between text-sm ${
						shipError ? "bg-orange-100" : "bg-neutral-100"
					}`}>
					<p className="flex-[0.55] text-neutral-500">Select Shipping Option</p>
					<div className="flex-[0.45] flex justify-between">
						{shipOptions.map((option, idx) => (
							<button
								key={idx}
								onClick={() => setShipMethod(option)}
								className={`w-16 text-[12px] py-0.5 capitalize duration-300 ease-in rounded-md max-w-20 border-[1px] border-neutral-400 text-neutral-400 hover:bg-neutral-900 hover:text-neutral-50 ${
									shipMethod === option ? "bg-neutral-900" : ""
								}`}>
								{option}
							</button>
						))}
					</div>
				</div>
				<div className="flex flex-col border-[1px] border-neutral-400 rounded-md">
					<div className="flex items-center mt-2 ml-2"></div>
					{!user && (
						<input
							type="email"
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Email"
							value={email}
							className={`${
								error &&
								!ValidateEmail(email) &&
								"user-email-input input-error font-light text-sm"
							} user-email-input font-light text-sm border-b-[1px] border-b-neutral-400`}
						/>
					)}
					<input
						name="street"
						type="text"
						value={address.street}
						onChange={inputOnchangeHandler}
						placeholder="Address"
						className={`${
							error &&
							!address.street &&
							"user-email-input input-error font-light text-sm"
						} user-email-input font-light text-sm border-b-[1px] border-b-neutral-400`}
					/>
					<input
						name="city"
						type="text"
						value={address.city}
						onChange={inputOnchangeHandler}
						placeholder="City"
						className={`${
							error &&
							!address.city &&
							"user-email-input input-error font-light text-sm"
						} user-email-input font-light text-sm border-b-[1px] border-b-neutral-400`}
					/>
					<div className="flex items-center gap-5">
						<input
							name="code"
							type="text"
							value={phone.countryCode}
							onChange={(e) =>
								setPhone({ ...phone, countryCode: e.target.value })
							}
							placeholder="+123"
							className={`${
								error &&
								!address.city &&
								"user-email-input input-error font-light text-sm"
							} user-email-input font-light text-sm border-b-[1px] border-b-neutral-400 flex-[0.3]`}
						/>
						<input
							name="phoneno"
							type="number"
							value={phone.phone}
							onChange={(e) => setPhone({ ...phone, phone: e.target.value })}
							placeholder="Phone no"
							className={`${
								error &&
								!address.city &&
								"user-email-input input-error font-light text-sm"
							} user-email-input font-light text-sm border-b-[1px] border-b-neutral-400 flex-[0.7]`}
						/>
					</div>
					<input
						name="province"
						type="text"
						value={address.province}
						onChange={inputOnchangeHandler}
						placeholder="Province / State"
						className={`${
							error &&
							!address.province &&
							"user-email-input input-error font-light text-sm"
						} user-email-input font-light text-sm border-b-[1px] border-b-neutral-400`}
					/>
					<input
						name="postalcode"
						type="text"
						value={address.postalcode}
						onChange={inputOnchangeHandler}
						placeholder="Postal-Code / Zip-code"
						className={`${
							error &&
							!address.postalcode &&
							"user-email-input input-error font-light text-sm"
						} user-email-input font-light text-sm border-b-[1px] border-b-neutral-400`}
					/>
					<select
						name="country"
						type="text"
						className={`${
							error &&
							!address.country &&
							"user-email-input input-error font-light text-sm"
						} user-email-input font-light text-sm `}
						onChange={(e) => inputOnchangeHandler(e, true)}>
						<option defaultValue="Select Country">select country</option>
						{countries.map((country) => (
							<option
								key={country.countryCode}
								className={`${
									error &&
									!address.country &&
									"user-email-input input-error font-light text-sm"
								} user-email-input font-light text-sm focus:ring-0`}>
								{country.countryName}
							</option>
						))}
					</select>
				</div>
			</div>
			{email.substr(email.length - 3) === "com" && (
				<div className="text-center email-verify">
					<span className="text-xs font-light">
						Please verify you have the correct email and address
					</span>
				</div>
			)}
			{error && (
				<div className="text-xs text-center user-email-input-error">
					<span>Hey! You have missing credentials!</span>
				</div>
			)}
			<div className="flex flex-row items-center mx-auto text-sm total-button">
				<button
					disabled={allowproceed}
					onClick={handleSubmitAddress}
					className="pr-5 mr-5 text-transparent duration-500 ease-in border-r-2 bg-clip-text bg-gradient-to-r from-orange-500 to-violet-500 hover:text-neutral-400"
					type="submit">
					PROCEED
				</button>

				<span
					onClick={() => dispatch(clearCartItem())}
					className="hover:text-neutral-400 hover:cursor-pointer ease-in duration-500 min-w-[100px]">
					CLEAR CART
				</span>
			</div>
			{allowproceed && (
				<div>
					{succeeded ? (
						<article className="mt-5 text-center">
							<h4>Thank you. Your payment was successful!</h4>
							<h4 className="my-4 text-xs text-green-700">
								Redirecting to {succeeded ? "success" : "canceled"} page...
							</h4>
						</article>
					) : (
						<article className="text-center text-xs p-1 mt-10 max-w-[70%] mx-auto rounded-sm text-neutral-500">
							<p>
								Hello, {user && `${user?.displayName},`} your total of{" "}
								{itemCount} {itemCount > 1 ? "items" : "item"} is $
								{finalPriceToPay} -{" "}
								<span className="text-cyan-800">
									tax & shipping fee included as applicable
								</span>
							</p>
						</article>
					)}
				</div>
			)}
			<form
				className={
					allowproceed
						? "block ease-in duration-300 w-full pt-1 mt-2 relative"
						: "hidden ease-in duration-300"
				}
				id="payment-form"
				onSubmit={handleSubmit}>
				<div className="flex max-w-[95%] mb-1 items-center mx-auto justify-end mr-4 md:mr-6 lg:mr-5 xl:mr-6">
					<SiMastercard size={20} className="mr-3 text-yellow-500" />
					<RiVisaLine size={30} className="mr-3 text-blue-900" />
					<div className="relative flex items-center mr-5">
						<RiVisaLine size={30} className="text-blue-800" />
						<span className="absolute bottom-[0px] right-[1px] text-[7px] italic">
							DEBIT
						</span>
					</div>
					<SiAmericanexpress size={20} className="mt-2 text-blue-600" />
				</div>
				<CardElement
					id="card-element"
					options={cardStyle}
					onChange={handleChange}
					className="w-[95%] mx-auto border-[1px] border-b-0 p-3 rounded-t-[4px] "
				/>
				{/* <div className="absolute right-[100px] sm:right-[105px] lg:right-[100px] z-10 w-[33px] h-6 top-[25.5%] bg-blur-faint" /> */}
				<button
					className="bg-neutral-800 w-[95%] flex mx-auto justify-center py-2 rounded-b-md"
					disabled={processing || disabled || succeeded}
					id="submit">
					<span
						className={
							processing || disabled || succeeded
								? "text-neutral-50 font-light"
								: "text-orange-500 font-light"
						}>
						{processing ? (
							<div className="spinner" id="spinner"></div>
						) : (
							"Pay now"
						)}
					</span>
				</button>
				{_error && (
					<div
						className="flex items-center justify-center mt-5 text-xs text-orange-700 card-error"
						role="alert">
						<GoAlert className="mr-2" />
						{_error}
					</div>
				)}
				<div className="w-[90%] mx-auto pt-1">
					<p className={succeeded ? "result-message" : "result-message hidden"}>
						Payment Succeeded,
						{user?.email === "tisijola7@gmail.com" && (
							<span>
								{" "}
								see the result in your
								<a
									className="text-blue-600"
									href={`https://dashboard.stripe.com/test/payments`}
									target="_blank"
									rel="noreferrer">
									{" "}
									Stripe Dashboard
								</a>
							</span>
						)}{" "}
						Refresh the page to pay again
					</p>
				</div>
			</form>
		</div>
	);
};

export default CheckoutForm;
