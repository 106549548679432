import React from "react";
import { REACT_APP_PLACEHOLDERIMG } from "../../constant";
import Truncate from "../../utils/truncate";

function ServicesComponent({
	handleOnChange,
	setSelectedService,
	filteredServices,
}) {
	const [showAll, setShowAll] = React.useState(false);
	const [descIndex, setDescIndex] = React.useState("");

	return (
		<div className="flex flex-col items-center mt-10 mb-5">
			<div
				className={`w-full mb-3 text-xl font-light text-center text-neutral-50 bg-gradient-to-l from-neutral-900/40 via-neutral-800 to-neutral-900/40 ${
					filteredServices?.length === 1
						? "flex flex-row justify-between px-5 items-center"
						: ""
				}`}>
				<label>
					{filteredServices?.length > 1 ? "Service" : "Selected Services"}
				</label>
				{filteredServices?.length === 1 && (
					<span
						onClick={() => setSelectedService([])}
						className="text-sm text-orange-400 cursor-pointer">
						Reset all
					</span>
				)}
			</div>
			{filteredServices?.map((service, idx) => (
				<div
					key={idx}
					className="flex flex-col gap-5 justify-between w-[100%] p-5 text-sm text-white sm:p-10 md:flex-row sm:items-start bg-gradient-to-l from-neutral-900/40 via-neutral-800 to-neutral-900/40 mb-2">
					<div className="w-[100%] h-[400px] md:h-[300px] md:w-[30%] xl:w-[40%]">
						<img
							id={idx}
							src={service?.image ? service?.image : REACT_APP_PLACEHOLDERIMG}
							alt={service.text}
							className={`w-[100%] h-[100%] mx-auto object-cover object-top rounded-xl ${
								!service?.image ? "opacity-[0.05]" : ""
							}`}
						/>
					</div>
					<div className="flex flex-col w-[100%] md:w-[70%]">
						<span className="mt-2 font-semibold sm:mt-0">{service.text}</span>
						<span className="font-thin">
							{service.duration} hours @ USD {service.price}
						</span>
						<span className="mt-10 font-thin duration-300 ease-in">
							{Truncate(service.description, 20, descIndex === idx && showAll)}
						</span>
						<span
							onClick={() => {
								setDescIndex(idx);
								setShowAll(!showAll);
							}}
							className="mt-10 text-xs uppercase text-neutral-500">
							{descIndex === idx && showAll ? "Show less" : "Show all"}
						</span>
					</div>
					{filteredServices?.length > 1 && (
						<div
							onClick={() => handleOnChange("service", service)}
							className="mt-10 font-normal duration-300 ease-in cursor-pointer md:mt-0 hover:bg-neutral-500">
							<span className="p-3 rounded-md w-fit bg-neutral-700">
								Select
							</span>
						</div>
					)}
				</div>
			))}
		</div>
	);
}

export default ServicesComponent;
