import React from "react";
import { AiFillStar } from "react-icons/ai";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { colorChart } from "../../src/utils/colorChart";
import {
	addToCartItem,
	increaseCartItem,
	selectCartItems,
} from "../slices/appSlices";
import Button from "./shared/Button";
import { isInCart } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const ProductDetails = function ({
	singleProduct,
	setSingleProduct,
	index,
	setIndex,
	showCaution,
	setShowCaution,
}) {
	const colorOptions = [];
	let singleProductColorOptions = [];

	if (
		singleProduct.color.toLowerCase() === "all color" ||
		singleProduct.color.toLowerCase() === "all-color"
	) {
		colorOptions.push(...colorChart);
	} else if (singleProduct.color.includes("/")) {
		singleProductColorOptions = singleProduct.color.split("/");
	} else if (
		!singleProduct.color.includes("/") &&
		!singleProduct.color.includes(",")
	) {
		singleProductColorOptions.push(singleProduct.color.toUpperCase());
	} else {
		singleProductColorOptions = singleProduct.color.split(", ");
	}
	if (singleProductColorOptions.length > 0) {
		for (let i = 0; i < singleProductColorOptions.length; i++) {
			// eslint-disable-next-line array-callback-return
			colorChart.map((color) => {
				if (color.name.startsWith(singleProductColorOptions[i])) {
					colorOptions.push(color);
				}
			});
		}
	}

	const MAX_RATING = 5;
	const MIN_RATING = 1;
	const cartItems = useSelector(selectCartItems);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [rating] = React.useState(
		Math.floor(Math.random() * (MAX_RATING - MIN_RATING + 1) + MIN_RATING)
	);
	const deci = Number(Math.random().toFixed(1));
	const [qnty, setQnty] = React.useState("");
	const [colorOption, colorOptionSetter] = React.useState("");

	const handleQuantity = (e) => {
		setShowCaution(false);
		setQnty(e.target.value);
	};

	// Sales logic
	const salesPrice =
		Math.round(
			(singleProduct.price -
				singleProduct.price * (singleProduct.percent / 100)) *
				100
		) / 100;

	// adding to cart
	const { description, _id, name, images } = singleProduct;
	const quantity = Number(qnty);
	const price =
		singleProduct.sales && singleProduct.percent > 0
			? salesPrice
			: singleProduct.price;

	const image = images[0];
	const Pen = { description, _id, name, price, image, quantity, colorOption };

	const addToCart = () => {
		if (quantity < 1 || quantity === 0) {
			setShowCaution(true);
		}
		setQnty(0);
		quantity > 0 && dispatch(addToCartItem(Pen));
	};

	const IncreaseItem = () => {
		setQnty(0);
		dispatch(increaseCartItem(Pen));
	};
	// Image index
	const increaseIndex = () => {
		if (index >= 0 && index !== singleProduct?.images?.length - 1) {
			setIndex(index + 1);
		}
	};
	const decreaseIndex = () => {
		if (index !== 0) {
			setIndex(index - 1);
		}
	};

	return (
		<div className="relative flex flex-col w-[100%] h-fit overflow-hidden mx-auto md:w-full p-2 py-10 mt-5 md:mt-0 md:py-0 md:flex-row items-start justify-center bg-gradient-to-r from-neutral-300 via-neutral-50 to-neutral-300 rounded-[30px] ">
			<div className="relative flex-[0.5] my-10 w-[100%] h-[480px] bg-neutral-100 object-cover rounded-lg">
				<div className="overflow-hidden h-fit">
					<img
						id={singleProduct.id}
						src={singleProduct?.images[index]}
						alt={singleProduct.id}
						className="object-contain overflow-hidden object-top h-full w-[100%] rounded-lg shadow-2xl"
					/>
				</div>
				<div className="text-[10px] text-blue-900 font-semibold absolute top-0 rounded-t-lg py-2 text-center bg-blur px-3 w-full">
					{index + 1} of {singleProduct?.images.length} photos of{" "}
					{singleProduct.name.toLowerCase()}
				</div>
				{singleProduct?.images?.length > 1 && (
					<MdOutlineKeyboardArrowLeft
						onClick={decreaseIndex}
						size={25}
						className="absolute bg-neutral-300 top-[45%] text-neutral-600 rounded-r-sm hover:cursor-pointer left-0"
					/>
				)}
				{singleProduct?.images?.length > 1 && (
					<MdOutlineKeyboardArrowRight
						onClick={increaseIndex}
						size={25}
						className="absolute bg-neutral-300 top-[45%] text-neutral-600 rounded-l-sm hover:cursor-pointer right-0"
					/>
				)}
				{singleProduct.sales && (
					<span className="text-white font-light bg-black w-full text-center text-[12px] py-1 px-2 absolute bottom-0 right-0 rounded-b-lg">
						On sales -{" "}
						<span className="text-orange-500 font-bold text-[15px]">
							{singleProduct.percent}%
						</span>{" "}
						off original price
					</span>
				)}
			</div>

			<div className="flex-[0.5] m-2 sm:m-14 sm:mx-5">
				<div className="border-b-[1px] border-neutral-500/20 pb-5 mb-5">
					<p className="mb-2 text-xl font-bold leading-6 text-blue-800">
						{singleProduct.name}
					</p>
					<p className="text-xs leading-4 uppercase text-neutral-500">
						{singleProduct.description}
					</p>
					<span className="text-lg font-bold text-blue-800">
						Price: $
						{singleProduct.sales && singleProduct.percent
							? !Number.isInteger(salesPrice)
								? salesPrice
								: `${salesPrice}.00`
							: !Number.isInteger(singleProduct.price)
							? singleProduct.price
							: `${singleProduct.price}.00`}
					</span>
					<div className="flex flex-col mx-1 mt-3 text-xs font-bold">
						<span className="pb-5 mb-5 border-b border-b-neutral-400">
							Length: {singleProduct.length}"
						</span>
						<div className="mb-2">
							Color: <span className="text-neutral-400">{colorOption}</span>
						</div>
						<div className="flex flex-wrap gap-2">
							{colorOptions.map((color, idx) => (
								<label
									onClick={(e) => colorOptionSetter(e.target.innerText)}
									className={`p-[3px] border-[0.5px] w-fit cursor-pointer hover:border-neutral-900 transition-all duration-500 ${
										colorOption === color.name
											? "border-black"
											: "border-neutral-400"
									}`}
									name="Color"
									for=""
									data-variant-id="">
									<div
										className="font-thin text-center text-white"
										style={{
											width: "100px",
											height: "130px",
											backgroundColor: "black",
											backgroundSize: "cover",
											backgroundPosition: "center",
											backgroundImage: `url(${color.image})`,
										}}>
										{color.name}
									</div>
								</label>
							))}
						</div>
						{/* <span>Color: {singleProduct.color}</span> */}
					</div>
				</div>

				<div>
					<div className="flex flex-col text-xs font-bold">
						<div className="flex">
							<span>Review: </span>
							{Array(rating)
								.fill()
								.map((_, i) => (
									<AiFillStar key={i} />
								))}
							<span>{rating + deci} rating</span>
						</div>
						{!isInCart(singleProduct, cartItems) && (
							<div className="relative mt-2">
								<label className="absolute top-1 left-3 bg-neutral-100 px-2 rounded-[2px] text-blue-800 font-semibold text-[10px]">
									Quantity
								</label>
								<input
									type="number"
									name="number"
									id="number"
									value={qnty}
									onChange={handleQuantity}
									placeholder=""
									className="mt-3 block w-1/2 md:w-2/3 lg:w-1/2 px-3 py-1 bg-neutral-100 text-neutral-600 rounded-[3px] border border-neutral-300 text-[10px] placeholder-neutral-600 focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200 disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-orange-500 invalid:text-orange-600 focus:invalid:border-orange-500 focus:invalid:ring-orange-500 outline-0"
								/>
							</div>
						)}
						{showCaution && !isInCart(singleProduct, cartItems) && (
							<span className="text-orange-700 text-[11px] font-light mt-3 mb-[-7px]">
								Hey! select your quantity
							</span>
						)}
						<div className="flex flex-row justify-between w-[80%">
							{isInCart(singleProduct, cartItems) ? (
								<Button
									isDisabled={singleProduct?.soldout}
									handleFunc={IncreaseItem}>
									Add More (+1)
								</Button>
							) : (
								<Button
									isDisabled={singleProduct?.soldout}
									handleFunc={addToCart}>
									Add to cart
								</Button>
							)}
							<div className="absolute top-0 pl-5 mt-5 ml-5 text-3xl font-light text-left text-gray-900 duration-300 ease-in right-5 hover:text-gray-600 hover:cursor-pointer">
								<span
									onClick={() => {
										setSingleProduct([]);
										navigate(`/`);
									}}>
									<VscClose size={30} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductDetails;
