import React from "react";

const TimeComponent = ({
	data,
	selectDate,
	timeSlotArray,
	handleFunc,
	isPicked,
}) => {
	return (
		<div>
			{data.map(
				(item) =>
					selectDate.toLowerCase()?.includes(item?.day.toLowerCase()) && (
						<div key={item.id}>
							<p className="absolute top-0 text-xs left-16 text-neutral-500">
								{item?.day}
							</p>
							<div className="flex flex-wrap text-xs text-black">
								{item?.time.map((slot, idx) => (
									<span
										onClick={() =>
											!timeSlotArray?.includes(slot) &&
											!slot.includes("-block") &&
											handleFunc(slot)
										}
										key={idx}
										className={`${
											timeSlotArray?.includes(slot) ||
											isPicked === slot ||
											slot.includes("-block")
												? "cursor-not-allowed text-neutral-200"
												: "bg-white  cursor-pointer md:hover:bg-neutral-600 md:hover:text-white "
										}  py-1 px-2 rounded-2xl mr-2 mb-2 duration-300 ease-in`}>
										{slot.split("-")[0]}
									</span>
								))}
							</div>
						</div>
					)
			)}
		</div>
	);
};

export default TimeComponent;
