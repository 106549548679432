function scrollToTop(desktop, mobile) {
	const targetPosition = desktop; // Target scroll position in pixels
	const targetPositionMobile = mobile; // Target scroll position in pixels

	// Determine the correct scrollable element (documentElement or body)
	const scrollElement = document.scrollingElement || document.documentElement;

	// Perform the scroll with a smooth animation
	if ("scrollTo" in window && typeof window.scrollTo === "function") {
		window.scrollTo({
			top: targetPosition,
			behavior: "smooth", // Smooth scrolling for modern browsers
		});
	} else {
		// Fallback for older browsers
		scrollElement.scrollTop = targetPositionMobile;
	}
}

export { scrollToTop };
