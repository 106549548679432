import React from "react";
import { auth, createUserProfileDocument } from "../firebase";

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
	const [user, setUser] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [changeInfo, setChangeInfo] = React.useState(false);

	React.useEffect(() => {
		const unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
			if (userAuth) {
				const userRef = await createUserProfileDocument(userAuth);
				userRef.onSnapshot((snapShot) => {
					setUser({
						id: snapShot.id,
						...snapShot.data(),
					});
					setTimeout(() => {
						setLoading(false);
					}, 2000);
				});
			} else {
				setTimeout(() => {
					setLoading(false);
					setUser(userAuth);
				}, 2000);
			}
		});
		return () => unsubscribeFromAuth();
	}, []);

	if (!user) {
		setTimeout(() => {
			setChangeInfo(true);
		}, 500);
	}

	const userContext = { user, loading };
	if (loading) {
		return (
			<div className="py-[100px] flex justify-center">
				{user ? (
					<div className="text-slate-900 flex flex-col items-center mt-[10px]">
						Authenticating User...
					</div>
				) : (
					<div className="text-slate-900 flex flex-col items-center mt-[10px]">
						{changeInfo
							? "No User Detected! Loading site now..."
							: "Authenticating User..."}
					</div>
				)}

				<div className="rounded-full progress">
					<div className="inner bg-slate-900"></div>
				</div>
			</div>
		);
	}
	return (
		<UserContext.Provider value={userContext}>{children}</UserContext.Provider>
	);
};

export default UserContextProvider;
