import React from "react";
import { FaTrash } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { REACT_APP_CANCELED, REACT_APP_STATUS } from "../../constant";
import Progressbar from "../shared/Progressbar";

const CalendarList = ({
	calendarList,
	showProgress,
	timeleft,
	handleDelete,
	setPicked,
	setConfirmDel,
	confirmDel,
	picked,
}) => {
	return (
		<>
			{calendarList.map((list) => (
				<div key={list.id}>
					<div
						className={`mb-[-10] w-[100%] mx-auto flex items-center ${
							showProgress && confirmDel && picked === list.id
								? "opacity-[1]"
								: "opacity-[0]"
						} ease-in duration-300`}>
						<Progressbar value={timeleft} max={100} />
					</div>
					<ul className="bg-white h-[100px] overflow-auto p-2 rounded-b-md general-scrollbar-hide">
						<div className="flex items-center justify-between border-b-[1px] pb-1 mb-1">
							{list.data.customer === list.data.email ? (
								<li className="text-xs text-neutral-600">
									Cst: {list.data.customer}
								</li>
							) : (
								<div className="flex flex-col">
									<li className="text-xs text-neutral-600">{`Customer: ${list.data.customer}`}</li>
									<li className="text-xs text-neutral-400">{`Email: ${list.data.email}`}</li>
								</div>
							)}
							<div className="relative flex items-center justify-center">
								<li className="px-3 py-1 text-xs text-white bg-orange-600 rounded-full">
									Duration:{" "}
									{list.data.duration > 1
										? `${list.data.duration} hrs`
										: `${list.data.duration} hr`}
								</li>
								{(new Date().getTime() > new Date(list.data.date).getTime() ||
									list.data.status !== REACT_APP_STATUS) && (
									<>
										{confirmDel && picked === list.id ? (
											<button
												disabled={showProgress}
												onClick={() => handleDelete(list.id)}
												className=" ml-2 border-l-[1px] max-w-[30px] h-[20px] max-h-[20px] pl-1 w-[30px] mb-1">
												<MdDeleteForever
													size={25}
													color={showProgress ? "grey" : "darkred"}
												/>
											</button>
										) : (
											<button
												disabled={showProgress}
												onClick={() => {
													setPicked(list.id);
													setConfirmDel(true);
												}}
												className="ml-2 border-l-[1px] max-w-[30px] h-[20px] max-h-[20px] pl-1 w-[30px] relative">
												<FaTrash
													size={19}
													color={showProgress ? "grey" : "lightgrey"}
												/>
												<span className="absolute top-1 right-[12px] font-bold text-xs text-white">
													?
												</span>
											</button>
										)}
									</>
								)}
								<div
									className={`${
										confirmDel && picked === list.id ? "opacity-1" : "opacity-0"
									} ease-in duration-300 absolute -bottom-5 right-0 text-orange-600`}>
									<p className=" text-[10px]">Are you sure ?</p>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-2 text-[11px] gap-x-2 text-neutral-800">
							<li className="text-orange-800 border-b-[0.5px]">
								Appointment Date: {list.data.date}
							</li>
							<li className="font-thin border-b-[0.5px] leading-3">
								Service: {list.data.service}
							</li>
							<li className="font-thin border-b-[0.5px]">
								Category: {list.data.category}
							</li>
							<li className="font-thin border-b-[0.5px]">
								Add-on Service: {list.data.addOnServices}
							</li>
							<li className="font-thin border-b-[0.5px]">
								Time Slot: {list.data.timeSlot.split("-")[0]}
							</li>
							<li className="font-thin border-b-[0.5px]">
								Balance: ${list.data.balance}
							</li>
							<li className="font-thin border-b-[0.5px]">
								Status:{" "}
								<span
									className={`${
										new Date().getTime() < new Date(list.data.date).getTime() &&
										list.data.status !== REACT_APP_CANCELED &&
										"text-green-700 font-semibold"
									} ${
										list.data.status === REACT_APP_CANCELED &&
										"text-neutral-400 italic line-through"
									} ${
										new Date().getTime() > new Date(list.data.date).getTime() &&
										"text-blue-800 font-semibold"
									}`}>
									{new Date().getTime() > new Date(list.data.date).getTime()
										? "Appointment is due"
										: list.data.status}
								</span>
							</li>
						</div>
					</ul>
				</div>
			))}
		</>
	);
};

export default CalendarList;
