export const SUPER_USER1 = "tisijola7@gmail.com";
export const SHIPPING_COST = {
	US: 7.99,
};
export const CANADA = "canada";
export const USERS = "users";
export const FREE_SHIPPING_AMOUNT = 200;
export const CURRENCY = "cad";
export const TAX_PERCENT = 0.13;
export const REACT_APP_CLOUD_NAME = "dhdccvkyt";
export const WHATSAPPBASEURL = "https://wa.me/";
export const REACT_APP_STATUS = "pending";

// Back end url
export const HTTPS_LINK = "http://localhost:5000";
// export const HTTPS_LINK = 'https://www.model-est.com'

export const REACT_APP_PLACEHOLDERIMG =
	"https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=";
export const REACT_APP_BOOKLIMIT = 12;
export const REACT_APP_SITE_URL =
	"https://www.pvginternational-s.com/customer/";
export const REACT_APP_AUTHORIZED_ID_ONE = "pvginternationals2812@gmail.com";
export const REACT_APP_BOOK = "/pvg/bookings";
export const REACT_APP_CANCELED = "canceled";
export const REACT_APP_HTTPS_LINK =
	"https://www.pvginternational-s.com/US/dashboard";

export const AUTHORIZED_ID = {
	id_one: "pvginternationals@gmail.com",
	id_two: "tisijola7@gmail.com",
};

export const LOCATION_AMERICA = "US";
export const LOCATION_NIGERIA = "NG";
export const LOCATIONS = {
	NG: "NIGERIA",
	US: "UNITED STATES",
};

export const sizes = {
	small: "xs",
	medium: "md",
	large: "xl",
	xlarge: "2xl",
	xxlarge: "3xl",
};
export const information = ["category", "add on services", "services"];
