import React from "react";
import { Calendar } from "react-date-range";

function DatePicker({ lockToOneMonth, turnOffBookings, handleSelect }) {
	const disabledDay = (date) => {
		const day = date.getDay(); // 1 = Monday, 2 = Tuesday
		return day === 1 || day === 2;
	};

	return !turnOffBookings ? (
		<Calendar
			min={Date.now()}
			max={
				lockToOneMonth
					? new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
					: ""
			}
			color={"rgb(251,146,60)"}
			date={new Date()}
			disabledDay={disabledDay}
			onChange={handleSelect}
			dateDisplayFormat="yyyy-MM-dd"
			showSelectionPreview={true}
			showMonthArrow={lockToOneMonth ? false : true}
			showMonthAndYearPickers={lockToOneMonth ? false : true}
		/>
	) : (
		<p className="text-lg text-center text-green-600">
			Bookings are closed for now!
		</p>
	);
}

export default DatePicker;
