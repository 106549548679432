/* eslint-disable array-callback-return */
import React from "react";
import { Helmet } from "react-helmet";
// import { MdLocationPin } from "react-icons/md";
import { Link } from "react-router-dom";
import Button from "../components/shared/Button";
import { db } from "../firebase";
import Progressbar from "../components/shared/Progressbar";
import { triggerProgress } from "../utils/triggerProgress";
import { REACT_APP_BOOK, REACT_APP_CANCELED } from "../constant";

const SingleBookPage = () => {
	const [singleBookingData, setSingleBookingData] = React.useState([]);
	// const [location, setLocation] = React.useState(null);
	const [alert, setAlert] = React.useState(false);
	const [deleting, setDeleting] = React.useState(false);
	const [timeleft, setTimeleft] = React.useState(0);
	React.useEffect(() => {
		const orderNo = window?.location?.pathname.split("/")[2];

		db.collection("all-bookings").onSnapshot((snapshot) => {
			const results = snapshot.docs.map((doc) => ({
				data: doc.data(),
				id: doc.id,
			}));
			const filterSingleBooking = results?.filter(
				(booking) => booking?.data.orderNo === orderNo
			);

			setSingleBookingData(filterSingleBooking);
		});

		// db.collection("location").onSnapshot((snapshot) => {
		// 	const results = snapshot.docs.map((doc) => ({
		// 		data: doc.data(),
		// 	}));
		// 	setLocation(results[0].data.location);
		// });
	}, []);

	React.useEffect(() => {
		if (deleting) {
			triggerProgress(setTimeleft);
		}
	}, [deleting]);

	const handleCancel = (id) => {
		setDeleting(true);
		setTimeout(() => {
			db.collection("all-bookings")
				.doc(id)
				.set({
					date: singleBookingData[0].data?.date,
					customer: singleBookingData[0].data?.customer,
					email: singleBookingData[0].data?.email,
					category: singleBookingData[0].data?.category,
					service: singleBookingData[0].data?.service,
					addOnServices: singleBookingData[0].data?.addOnServices,
					orderNo: singleBookingData[0].data?.orderNo,
					quantity: singleBookingData[0].data?.quantity,
					transactionDate: singleBookingData[0].data?.transactionDate,
					payMethod: singleBookingData[0].data?.payMethod,
					product: singleBookingData[0].data?.product,
					sender: singleBookingData[0].data?.sender,
					total: singleBookingData[0].data?.total,
					balance: singleBookingData[0].data?.balance,
					duration: singleBookingData[0].data?.duration,
					status: "canceled",
					timeSlot: singleBookingData[0].data?.timeSlot,
				})
				.then(() => {
					setAlert(true);
					setDeleting(false);
					setTimeleft(0);
				})
				.catch((error) => {
					console.error("Error writing document: ", error);
				});
			db.collection("blacklist")
				.add({
					email: singleBookingData[0].data?.email,
					date: Date.now(),
				})
				.then(() => {})
				.catch((error) =>
					console.log("Email could not be added to blacklist!")
				);
		}, 10000);
	};

	if (alert) {
		setTimeout(() => {
			setAlert(false);
		}, 3000);
	}

	return (
		<div className="relative bg-neutral-900 h-[100vh]">
			<Helmet>
				<title>my-calendar</title>
			</Helmet>
			<div className="md:h-[100%] bg-gradient-to-r from-neutral-100 to-orange-200 md:w-[50%] lg:w-[35%] 2xl:w-[25%] mx-auto pt-[40px] flex flex-col">
				<div className="">
					<div className="relative">
						<div className="px-5 border-b-[1px] border-orange-700">
							<span className="text-5xl font_cursive">PVG</span>
						</div>
						<div className="absolute right-0 px-8 py-2 text-white capitalize bg-green-600 rounded-l-full -top-6">
							{singleBookingData?.length ? (
								<p>{singleBookingData[0]?.data?.status}</p>
							) : (
								<p>Empty</p>
							)}
						</div>
					</div>
					{singleBookingData?.length ? (
						<div className="px-8">
							<div className="flex justify-start w-full mt-5">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Order No: {singleBookingData[0]?.data?.orderNo}
								</span>
							</div>
							<div className="flex justify-end w-full mt-1">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Appointment Date: {singleBookingData[0]?.data?.date}
								</span>
							</div>
							<div className="flex justify-start w-full mt-1">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Category: {singleBookingData[0]?.data?.category}
								</span>
							</div>
							<div className="flex justify-end w-full mt-1">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Service: {singleBookingData[0]?.data?.service}
								</span>
							</div>
							<div className="flex justify-start w-full mt-1">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Add-on Services: {singleBookingData[0]?.data?.addOnServices}
								</span>
							</div>
							<div className="flex justify-end w-full mt-1">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Duration: {singleBookingData[0]?.data?.duration} Hours
								</span>
							</div>
							<div className="flex justify-start w-full mt-1">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Time: {singleBookingData[0]?.data?.timeSlot}
								</span>
							</div>
							<div className="flex justify-end w-full mt-1">
								<span className="px-5 py-2 text-sm bg-orange-100 rounded-full text-neutral-600">
									Transaction Date:{" "}
									{singleBookingData[0]?.data?.transactionDate}
								</span>
							</div>
						</div>
					) : (
						<div className="mt-5 ">
							<span className="ml-10 text-orange-800">
								No data to deplay...
							</span>
						</div>
					)}
					{/* <div className="mt-5">
						<a
							href={`${process.env.REACT_APP_GOOGLESEARCH + location}`}
							target="_blank"
							rel="noreferrer"
							className="bg-neutral-300 text-black px-5 text-xs font-light flex items-center justify-center hover:text-neutral-600 ease duration-300 border-y-[1px] border-neutral-50">
							<MdLocationPin size={25} className="my-1 mr-2 text-orange-500" />
							<span className="navStyleChildWhite">We are @ {location}</span>
						</a>
					</div> */}
				</div>
				<div className="flex-[1]  flex flex-col items-center justify-center">
					<div className="text-2xl font_cursive">
						<p className="text-transparent bg-clip-text bg-gradient-to-r from-slate-900 to-orange-900">
							A whole new you with PVG...
						</p>
					</div>
					<div className="flex items-center mt-10 mb-5">
						<Link to={`${REACT_APP_BOOK}`} className="mx-2">
							<Button
								children="New Booking ?"
								isTrans={true}
								isBlackTxt={true}
								isSmallTxt={true}
								isCancel={
									singleBookingData[0]?.data?.status === REACT_APP_CANCELED &&
									true
								}
							/>
						</Link>
						<div
							onClick={() =>
								singleBookingData[0]?.data?.status !== REACT_APP_CANCELED &&
								handleCancel(singleBookingData[0]?.id)
							}
							className="mx-2">
							<Button
								children="Cancel ?"
								isTrans={true}
								isBlackTxt={true}
								isSmallTxt={true}
								isCancel={
									(singleBookingData.length === 0 ||
										singleBookingData[0]?.data?.status ===
											REACT_APP_CANCELED) &&
									true
								}
							/>
						</div>
					</div>
					<div className={`${deleting ? "opacity-1" : "opacity-0"} w-[80%]`}>
						<Progressbar value={timeleft} max={100} />
					</div>
					<div
						className={`${
							alert ? "opacity-1" : "opacity-0"
						} text-xs text-green-700 ease-in duration-300 mb-5`}>
						<span>You have successfully canceled your booking</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SingleBookPage;
