/* eslint-disable array-callback-return */
import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useNavigate, useParams } from "react-router-dom";
import emailjs from "emailjs-com";
import { GoAlert } from "react-icons/go";
import {
	LOCATION_NIGERIA,
	REACT_APP_AUTHORIZED_ID_ONE,
	REACT_APP_BOOKLIMIT,
	REACT_APP_SITE_URL,
	REACT_APP_STATUS,
} from "../../constant";
import { db } from "../../firebase";
import Button from "../shared/Button";
import { triggerProgress } from "../../utils/triggerProgress";
import TimeComponent from "./TimeComponent";
import CalendarList from "./CalendarList";
import DayTimeSlotComponent from "./DayTimeSlotComponent";
import { BsFillUnlockFill } from "react-icons/bs";
import removeHTMLTags from "../../utils/removeHTMLTags";
import reload from "../../utils/reload";
import ThirtyDaysCalendar from "../shared/ThirtyDaysCalendar";
import CloseBookings from "../shared/CloseBookings";
import DatePicker from "../shared/DatePicker";

const CalendarPage = () => {
	const { country } = useParams();
	const dateToday = new Date();
	const [selectDate, setSelectDate] = React.useState(null);
	const [blockDate, setBlockDate] = React.useState(null);
	const [isSuccess, setSuccess] = React.useState(false);
	const [lockToOneMonth, setLockToOneMonth] = React.useState(false);
	const [turnOffBookings, setTurnOffBookings] = React.useState(false);
	const [bookLimit, setBookLimit] = React.useState(false);
	const [timeleft, setTimeleft] = React.useState(0);
	const [userEmail, setUserEmail] = React.useState("");
	const [bookingData, setBookingData] = React.useState({});
	const [error, setError] = React.useState(false);
	const orderNo = `PVG${Math.random().toString(36).slice(2)}`;
	const [calendarList, setCalendarList] = React.useState([]);
	const [timeSlotDB, setTimeSlotsDB] = React.useState([]);
	const [showProgress, setShowProgress] = React.useState(false);
	const [showNextMonthWarning, setShowNextMonthWarning] = React.useState(false);
	const [confirmDel, setConfirmDel] = React.useState(false);
	const [dailyMax, setDailyMax] = React.useState(0);
	const [timeSlotArray, setTimeSlotArray] = React.useState([]);
	const [_timeSlot, setTimeSlot] = React.useState("");
	const [isPicked, setIsPicked] = React.useState("");
	const [picked, setPicked] = React.useState("");
	const [blockedDatesList, setBlockedDatesList] = React.useState([]);
	const [policyService, setPolicyService] = React.useState({});
	const navigate = useNavigate();

	let calendarmax = "calendarmax";
	let allbookings = "all-bookings";
	let timeSlot = "timeSlot";
	let blockdates = "block-dates";
	let admin = "admin";

	if (country === LOCATION_NIGERIA) {
		calendarmax = "calendarmax-NG";
		allbookings = "all-bookings-NG";
		timeSlot = "timeSlot-NG";
		blockdates = "block-dates-NG";
		admin = "adminNG";
	}

	React.useEffect(() => {
		db.collection(calendarmax).onSnapshot((snapshot) => {
			snapshot.docs.map((doc) => {
				setLockToOneMonth(doc.data()?.lockToOneMonth);
				setTurnOffBookings(doc.data()?.turnOffBookings);
			});
		});

		db.collection(allbookings).onSnapshot((snapshot) => {
			const arr = [];
			snapshot.docs.map((doc) => {
				arr.push({ id: doc.id, data: doc.data() });
			});
			setCalendarList(arr);
		});

		db.collection(timeSlot).onSnapshot((snapshot) => {
			const arr = [];
			snapshot.docs.map((doc) => {
				const day = doc.data().day;
				const time = doc.data().time;
				const order = doc.data().order;
				arr.push({ id: doc.id, day: day, time: time, order: order });
			});
			arr.sort(function (a, b) {
				return a.order - b.order;
			});
			setTimeSlotsDB(arr);
		});

		db.collection(blockdates).onSnapshot((snapshot) => {
			const array1 = [];
			const array2 = [];
			snapshot.docs.map((doc) => {
				array1.push(new Date(doc.data().blockDate.seconds * 1000));
				array2.push({
					id: doc.id,
					day: new Date(doc.data().blockDate.seconds * 1000),
				});
			});
			setBlockedDatesList(array2);
		});

		db.collection(admin).onSnapshot((snapshot) => {
			const data = snapshot.docs.map((doc) => {
				return doc.data();
			});
			setPolicyService(data[0]);
		});
	}, [admin, allbookings, blockdates, calendarmax, timeSlot]);

	React.useEffect(() => {
		if (showProgress) {
			triggerProgress(setTimeleft);
		}
	}, [showProgress]);

	const addOnServices = policyService["add on services"];
	const services = policyService["services"];
	const categories = policyService["category"];

	const unBlockSlot = (slot, id, idx) => {
		db.collection(timeSlot).onSnapshot((snapshot) => {
			const res = snapshot.docs
				.map((doc) => {
					if (doc.id === id) {
						return {
							id: doc.id,
							data: doc.data(),
						};
					}
				})
				.filter((x) => x);
			const time = res?.[0]?.data?.time;
			time[idx] = slot.split("-")[0];
			db.collection(timeSlot).doc(id).set({ time: time }, { merge: true });
		});
		reload(navigate);
	};
	const blockSlot = (slot, id, idx) => {
		db.collection(timeSlot).onSnapshot((snapshot) => {
			const res = snapshot.docs
				.map((doc) => {
					if (doc.id === id) {
						return {
							id: doc.id,
							data: doc.data(),
						};
					}
				})
				.filter((x) => x);
			const time = res?.[0]?.data?.time;
			time[idx] = `${slot}-block`;
			db.collection("timeSlot").doc(id).set({ time: time }, { merge: true });
		});
		reload(navigate);
	};
	//
	const submitBlockDate = () => {
		// add block date to db
		db.collection(blockdates).add({ blockDate });
		setBlockDate("");
	};
	const submitUnblockDate = (id) => {
		// remove block date from db
		db.collection(blockdates).doc(id).delete();
	};
	//
	const handleSelectTimeSlot = (t) => {
		setTimeSlot(t);
		setIsPicked(t);
	};

	const handleSelect = (date) => {
		if (Date.parse(date) <= new Date().setDate(new Date().getDate() + 1))
			return;
		if (selectDate) {
			setSelectDate(null);
		}

		if (bookLimit) {
			setBookLimit(false);
		}

		if (date > dateToday) {
			db.collection(allbookings).onSnapshot((snapshot) => {
				const results = snapshot.docs.map((doc) => ({
					data: doc.data(),
				}));
				const filterBydate = results?.filter(
					(booking) => booking?.data.date === date.toDateString()
				);

				const timeSlotArr = filterBydate?.map((time) => time?.data?.timeSlot);
				setTimeSlotArray(timeSlotArr);

				let maxDailyBookingHour = 0;
				for (let i = 0; i < filterBydate.length; i++) {
					maxDailyBookingHour += filterBydate?.[i]?.data?.duration;
				}
				if (
					maxDailyBookingHour < REACT_APP_BOOKLIMIT &&
					REACT_APP_BOOKLIMIT - maxDailyBookingHour >= 1 &&
					timeSlotArray?.length < timeSlotDB?.length
				) {
					setSelectDate(date.toDateString());
					setDailyMax(maxDailyBookingHour);
				} else {
					setBookLimit(true);
				}
			});
		}
		setSelectDate(false);
	};

	const handleDelete = (id) => {
		setShowProgress(true);

		setTimeout(() => {
			db.collection(allbookings)
				.doc(id)
				.delete()
				.catch((error) => {
					console.error("Error removing document: ", error);
				});
			setShowProgress(false);
			setTimeleft(0);
		}, 10000);

		setTimeout(() => {
			reload(navigate);
		}, 10000);
	};
	//
	const handleOnChange = (e) => {
		setBookingData({ ...bookingData, [e.target.name]: e.target.value });
	};
	// stripe percent logic
	const stripeDeduction = +(
		+process.env.REACT_APP_BOOKING_PRICE * +process.env.REACT_APP_STRIPE_PERCENT
	).toFixed(2);
	let totalBookingPrice = 0;
	let serviceDuration = 0;
	services?.filter((item) => {
		const text = removeHTMLTags(item?.text);
		if (text === bookingData?.service) {
			totalBookingPrice += Number(item?.price);
			serviceDuration += Number(item?.duration);
		}
	});
	addOnServices?.filter((item) => {
		const text = removeHTMLTags(item?.text);
		if (text === bookingData?.addOnServices) {
			totalBookingPrice += Number(item?.price);
		}
	});
	const totalDue = +process.env.REACT_APP_BOOKING_PRICE + stripeDeduction;
	const bookingBalance =
		totalBookingPrice > totalDue
			? totalBookingPrice - +process.env.REACT_APP_BOOKING_PRICE
			: +process.env.REACT_APP_BOOKING_PRICE - totalBookingPrice;
	//
	const messageParams = {
		name: userEmail,
		message: `Hi ${userEmail}. Your hair appointment has been booked for ${selectDate}, ${_timeSlot}. Your details are as follow - Category: ${bookingData.category}, Services: ${bookingData.service}, Add-On-Service: ${bookingData.addOnServices}. Please ensure to come early. Thank you for your patronage!`,
		client: userEmail,
		sender: "PVG",
		quantity: 1,
		product: "Hair Appointment",
		payMethod: "Card",
		total: `${process.env.REACT_APP_BOOKING_PRICE}.00`,
		balance: `$${bookingBalance}`,
		orderNo: orderNo,
		date: dateToday.toDateString(),
		link: `${REACT_APP_SITE_URL}${orderNo}`,
		serviceCharge: `$${stripeDeduction}`,
		serviceDuration: `${serviceDuration} hours`,
		timeSlot: _timeSlot,
	};
	const SendClientSuccessfulPurchaseEmail = () => {
		emailjs
			.send(
				process.env.REACT_APP_EMAILJS_SERVICE,
				process.env.REACT_APP_EMAILJS_CUSTOMER_TEMPLATE,
				messageParams,
				process.env.REACT_APP_EMAILJS_API
			)
			.then((res) => {})
			.catch((err) => console.log(err));
	};

	const handleSubmit = () => {
		db.collection(allbookings)
			.add({
				date: selectDate,
				customer: userEmail,
				email: userEmail,
				category: bookingData.category,
				service: bookingData.service,
				addOnServices: bookingData.addOnServices,
				orderNo: orderNo,
				quantity: 1,
				transactionDate: dateToday.toDateString(),
				payMethod: "Card",
				product: "Hair Appointment",
				sender: "PVG",
				total: `$${process.env.REACT_APP_BOOKING_PRICE}.00`,
				balance: bookingBalance,
				duration: serviceDuration,
				status: REACT_APP_STATUS,
				timeSlot: _timeSlot,
			})
			.then(() => {
				console.log(`SUCCESSFULL`);
				setSuccess(true);
			})
			.catch((error) => console.log("Error***************" + error.message));

		setUserEmail("");
		setBookLimit(false);
		setSelectDate("");
		setBookingData({ addOnServices: "", category: "", service: "" });

		setTimeout(() => {
			SendClientSuccessfulPurchaseEmail();
		}, 1000);
	};

	//  filter services option
	const filteredServices = services?.filter((service) => {
		if (REACT_APP_BOOKLIMIT - dailyMax >= 2) {
			return service;
		}
		if (REACT_APP_BOOKLIMIT - dailyMax < 2) {
			return service?.duration === 1.5;
		}
	});

	const handleLock30DaysMax = () => {
		db.collection(calendarmax)
			.doc(`${REACT_APP_AUTHORIZED_ID_ONE}/`)
			.set({ lockToOneMonth: lockToOneMonth ? false : true }, { merge: true });

		showNextMonthWarning && setShowNextMonthWarning(false);
	};
	const handleTurnOffBookings = () => {
		db.collection(calendarmax)
			.doc(`${REACT_APP_AUTHORIZED_ID_ONE}/`)
			.set(
				{ turnOffBookings: turnOffBookings ? false : true },
				{ merge: true }
			);
	};

	return (
		<div className="w-[95%] mx-auto md:w-full">
			<div className="flex flex-col justify-center w-full mt-10 lg:mb-10 lg:flex-row">
				<div className="flex-[0.5]">
					<div className="flex justify-center w-full">
						<DatePicker
							lockToOneMonth={lockToOneMonth}
							turnOffBookings={turnOffBookings}
							handleSelect={handleSelect}
						/>
					</div>
					{!bookLimit && (
						<div className="">
							<div
								className={
									"select-date py-[5px] border-t-[1px] w-[332px] mx-auto"
								}>
								<span>
									{selectDate ? `Date selected ${selectDate}` : "Select a date"}
								</span>
							</div>
						</div>
					)}
					{bookLimit && (
						<div className="">
							<div className="select-date py-[5px] border-t-[1px]">
								<span>We are booked for this date</span>
							</div>
						</div>
					)}
					<div className="flex justify-center my-10">
						<label className="bookLabel w-[332px]">
							<p className="top-[0px]">Customer email address...</p>
							<input
								type="text"
								autoComplete="off"
								placeholder=" "
								name="email"
								id="email"
								value={userEmail}
								onChange={(e) => setUserEmail(e.target.value)}
								className={`outline-0 focus:outline-none focus:ring-0 w-full border-[0px] text-sm mt-5 md:mt-0`}
							/>
						</label>
					</div>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						if (
							!bookingData.service &&
							!bookingData.category &&
							!bookingData.addOnServices &&
							!selectDate &&
							!userEmail
						) {
							setError(true);
						} else {
							handleSubmit();
						}
					}}
					className="lg:ml-10 pt-2 md:pt-0 mt-[10px] flex-[0.5]">
					<div className="mb-[10px]">
						<div className="text-neutral-900 font-light text-[10px] pl-3 bg-neutral-200 py-[2px]">
							<label>Category</label>
						</div>
						<select
							className="select-category"
							onChange={handleOnChange}
							id="category"
							value={bookingData.category}
							name="category">
							<option value="" disabled selected>
								Select category
							</option>
							{categories?.map((category, idx) => (
								<option
									key={idx}
									dangerouslySetInnerHTML={{
										__html: category.text || category,
									}}
								/>
							))}
						</select>
					</div>
					<div className="mb-[10px]">
						<div className="text-neutral-900 font-light text-[10px] pl-3 bg-neutral-200 py-[2px]">
							<label>Services</label>
						</div>
						<select
							className="select-category"
							onChange={handleOnChange}
							id="Service"
							value={bookingData.service}
							name="service">
							<option value="" disabled selected>
								Select service
							</option>
							{filteredServices?.map((service, idx) => (
								<option
									key={idx}
									dangerouslySetInnerHTML={{
										__html: service.text || service,
									}}
								/>
							))}
						</select>
					</div>
					<div className="mb-[10px]">
						<div className="text-neutral-900 font-light text-[10px] pl-3 bg-neutral-200 py-[2px]">
							<label>Add-On Services</label>
						</div>
						<select
							className="select-category"
							onChange={handleOnChange}
							id="addOnServices"
							value={bookingData.addOnServices}
							name="addOnServices">
							<option value="" disabled selected>
								Select add-on
							</option>
							{addOnServices?.map((ad, idx) => (
								<option
									key={idx}
									dangerouslySetInnerHTML={{
										__html: ad.text || ad,
									}}
								/>
							))}
						</select>
					</div>
					<div className="relative">
						<div className="mb-1 text-xs font-light text-neutral-500">
							<span>Time slot -</span>
						</div>
						{selectDate ? (
							<TimeComponent
								data={timeSlotDB}
								selectDate={selectDate}
								timeSlotArray={timeSlotArray}
								handleFunc={handleSelectTimeSlot}
								isPicked={isPicked}
							/>
						) : (
							<span className="text-[10px] font-thin text-neutral-500">
								Select date to display daily time slot...
							</span>
						)}
					</div>
					{REACT_APP_BOOKLIMIT - dailyMax < 2 && (
						<div className="text-orange-300 font-thin text-[11px] bg-neutral-800 p-2 rounded-lg">
							<span>
								<span className="text-sm">We are almost booked up!</span> <br />
								We can only accommodate services of one hour 30 minutes or less.
							</span>
						</div>
					)}
					{error && (
						<span className="text-rose-600 text-[14px] w-[100%] mx-auto mb-10">
							Missing Condential: Date...
						</span>
					)}
					{isSuccess && (
						<span className="text-blue-600 text-[14px] w-[100%] mx-auto mb-10">
							Booking submitted successfully!
						</span>
					)}
					<div className="text-center mt-[30px]">
						{bookingData.service &&
							bookingData.category &&
							bookingData.addOnServices &&
							selectDate &&
							userEmail && (
								<Button type="submit" isTrans={true}>
									Submit Booking
								</Button>
							)}
					</div>
				</form>
			</div>

			<div className="grid md:grid-cols-2 gap-3 border-t-[1px] pt-5">
				{calendarList.length ? (
					<CalendarList
						calendarList={calendarList}
						showProgress={showProgress}
						timeleft={timeleft}
						handleDelete={handleDelete}
						setPicked={setPicked}
						setConfirmDel={setConfirmDel}
						confirmDel={confirmDel}
						picked={picked}
					/>
				) : (
					<div>
						<span className="text-xs text-orange-800">
							No data to display...
						</span>
					</div>
				)}
			</div>
			<div className=" mt-10 border-t-[0.5px] pt-5">
				<div className="flex flex-col sm:flex-row sm:items-start gap-x-5 justify-between w-full pb-5 mb-5 border-b-[1px]">
					<div className="mb-5 sm:mb-0 flex flex-col [50%]">
						<div className="mb-2 text-center text-orange-600">
							<p>Date Blocker</p>
						</div>
						<div className="self-center">
							<div className=" bg-neutral-200">
								<DatePicker handleSelect={(date) => setBlockDate(date)} />
							</div>
							{blockDate && (
								<div className="w-full mx-auto flex flex-row items-center text-[10px] text-orange-800 uppercase mb-0 pb-[5px] pt-[10px]">
									<div className="flex-[0.9] w-full text-center flex flex-row items-center">
										<div className="mr-2 text-orange-800">
											<GoAlert />
										</div>
										<span>
											{blockDate.toDateString()}. You have chosen to block this
											date
										</span>
									</div>
									<div className="flex-[0.1] px-2 text-center border-[1px] border-orange-800 rounded-sm">
										<button
											onClick={submitBlockDate}
											className="text-orange-800 uppercase">
											Block
										</button>
									</div>
								</div>
							)}
						</div>
						{blockedDatesList.length > 0 && (
							<div className="grid grid-cols-2 gap-1">
								{blockedDatesList.map((block, idx) => (
									<ul
										key={idx}
										className="flex items-center  text-xs text-orange-700 font-thin p-2 pr-0 rounded-b-md bg-neutral-100 h-[30px]">
										<li className="flex-[0.7]">{block.day.toDateString()}</li>
										<button
											onClick={() => submitUnblockDate(block.id)}
											className="rounded-br-md text-green-800 h-[30px] flex flex-[0.3] justify-center items-center">
											<BsFillUnlockFill size={15} />
										</button>
									</ul>
								))}
							</div>
						)}
					</div>
					<div className="w-[100%] md:w-[50%]">
						<ThirtyDaysCalendar
							condition={lockToOneMonth}
							text={
								lockToOneMonth
									? "Open Calendar beyond 1 Month"
									: "Lock Calendar to 1 Month"
							}
							func={handleLock30DaysMax}
						/>
						<CloseBookings
							condition={turnOffBookings}
							text={turnOffBookings ? "Turn On Bookings" : "Turn Off Bookings"}
							func={handleTurnOffBookings}
						/>
						{showNextMonthWarning && lockToOneMonth && (
							<p className="capitalize w-full text-orange-800 text-center mt-3.5 text-sm">
								Please open calendar before setting
							</p>
						)}
					</div>
				</div>
				<div className="mb-2 text-orange-600">
					<p>Time slot blocker</p>
				</div>
				<DayTimeSlotComponent
					timeSlotDB={timeSlotDB}
					timeSlotArray={timeSlotArray}
					handleSelectTimeSlot={handleSelectTimeSlot}
					unBlockSlot={unBlockSlot}
					blockSlot={blockSlot}
				/>
			</div>
		</div>
	);
};

export default CalendarPage;
