import { REACT_APP_CLOUD_NAME } from "../constant";

export const uploadImage = (e, setImagesFile) => {
	const data = new FormData();
	const images = e.target.files;
	const returnImg = [];
	for (var i = 0; i < images.length; i++) {
		data.append("file", images[i]);

		data.append(
			"upload_preset",
			`${process.env.REACT_APP_CLOUD_UPLOAD_PRESET}`
		);
		data.append("cloud_name", `${process.env.REACT_APP_CLOUD_NAME}`);
		data.append("folder", "pvg-upload");

		fetch(
			`https://api.cloudinary.com/v1_1/${REACT_APP_CLOUD_NAME}/image/upload`,
			{
				method: "post",
				body: data,
			}
		)
			.then((res) => res.json())
			.then((data) => {
				if (data.url) returnImg.push(data.url);
			})
			.catch((err) => console.log("ERROR******", err));
	}
	setImagesFile(returnImg);
};
