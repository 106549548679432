/* eslint-disable array-callback-return */
import React from "react";
import useSWR from "swr";
import Layout from "../components/shared/Layout";
import Card from "../components/Card";
import ProductDetails from "../components/ProductDetails";
import { Helmet } from "react-helmet";
import { BiUpArrowAlt } from "react-icons/bi";
import { GrProductHunt } from "react-icons/gr";
import { VscClose } from "react-icons/vsc";
import Pagination from "../components/shared/Pagination";
import Pagination2 from "../components/shared/Pagination2";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { MdKeyboardArrowRight } from "react-icons/md";
import CtaButton from "../components/shared/CtaButton";

const Home = function () {
	const [prod, setShowProduct] = React.useState(false);
	const [banner, closeBanner] = React.useState(false);
	const [isPicked, setIsPicked] = React.useState(false);
	const [index, setIndex] = React.useState(0);
	const [showWhatsapp, setShowWhatsapp] = React.useState(false);
	const [singleProduct, setSingleProduct] = React.useState([]);
	const [globalFilter, setGlobalFilter] = React.useState([]);
	const [showCaution, setShowCaution] = React.useState(false);
	const [selectedProduct, setSelectedProduct] = React.useState("");
	const [showTopBtn, setShowTopBtn] = React.useState(false);
	const contentPerPage = 10;
	const [chunkIndex, setChunkIndex] = React.useState(0);
	const [chunkIndex2, setChunkIndex2] = React.useState(0);
	const [filterIndex, setFilterIndex] = React.useState(0);
	const filterList = [
		{
			id: "2",
			filterName: "Hair & Extensions",
			name: "Hair",
			src: require("../assets/hair.jpeg"),
		},
		{
			id: "3",
			filterName: "Handmade Beads",
			name: "Beads",
			src: require("../assets/bead.jpeg"),
		},
	];

	React.useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "pageview", // You can set your event name
			variableName: "variableValue", // Customize with your own variables
		});
		let timeOut;

		clearTimeout(timeOut);

		timeOut = setTimeout(function () {
			closeBanner(true);
		}, 10000);
	}, []);

	React.useEffect(() => {
		let mountedRef = true;
		let timeOut;
		clearTimeout(timeOut);

		timeOut = setTimeout(() => {
			if (!mountedRef) return null;
			setShowProduct(true);
		}, 2000);

		return () => {
			mountedRef = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.scrollY]);

	const goToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	async function fetcherFunction(...args) {
		let allBeads = [];
		let allHair = [];
		const response = await fetch(...args);
		const { products } = await response.json();

		allBeads = products
			.filter(
				(b) =>
					b.type.toLowerCase().includes("bead") ||
					b.type.toLowerCase().includes("gold plated") ||
					b.type.toLowerCase().includes("accessories")
			)
			.sort((a, b) => +a.price - +b.price);

		allHair = products
			.filter(
				(b) =>
					b.type.toLowerCase().includes("braid") ||
					b.type.toLowerCase().includes("wig")
			)
			.sort((a, b) => +a.price - +b.price);

		return { allBeads, allHair };
	}

	const { data, error, isLoading } = useSWR(
		"/api/v1/products",
		fetcherFunction
	);

	if (isLoading) {
		return (
			<div className="justify-center text-blue-600 flex flex-col items-center mt-[50px]">
				Loading Data...
			</div>
		);
	}

	if (error)
		return (
			<div className="justify-center text-orange-600 flex flex-col items-center mt-[50px]">
				Failed to load data
			</div>
		);

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	const handlePickProduct = (val) => {
		setSelectedProduct(val);
	};

	function totalNumberOfFilterChunked() {
		let total = 0;
		if (globalFilter.length > contentPerPage) {
			total = globalFilter.length / contentPerPage;
		}

		return Math.ceil(total);
	}
	const chunkFilterCards = [];
	let _idx = 0;
	for (let i = 0; i < totalNumberOfFilterChunked(); i++) {
		chunkFilterCards.push(globalFilter.slice(_idx, contentPerPage + _idx));
		_idx += contentPerPage;
	}

	let filterData = globalFilter;
	if (globalFilter.length > contentPerPage) {
		filterData = chunkFilterCards[filterIndex];
	}

	const totalNumberOfChunked = data?.allHair.length / contentPerPage;
	const chunkCards = [];
	let _index = 0;
	for (let i = 0; i < totalNumberOfChunked; i++) {
		chunkCards.push(data?.allHair.slice(_index, contentPerPage + _index));
		_index += contentPerPage;
	}

	const totalNumberOfChunked2 = data?.allBeads.length / contentPerPage;
	const chunkCards2 = [];
	let _index2 = 0;
	for (let i = 0; i < totalNumberOfChunked2; i++) {
		chunkCards2.push(data?.allBeads.slice(_index2, contentPerPage + _index2));
		_index2 += contentPerPage;
	}
	const Beauties = () => {
		return !globalFilter.length ? (
			<div className="mt-[-40px]">
				{data?.allHair.length > 1 ? (
					<div className="border-b-[1px] mt-16 md:mt-20 border-b-neutral-300 pb-5">
						<div className="text-white ml-[30px] xl:ml-[100px] font-normal capitalize-first">
							<span>hair & extensions</span>
						</div>
						<div className="grid justify-center w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
							{chunkCards[chunkIndex]?.map((product) => (
								<div
									key={product._id}
									className="flex flex-row items-center justify-center px-2 mb-2">
									<Card
										key={product._id}
										product={product}
										setSingleproduct={setSingleProduct}
										scrollToTop={scrollToTop}
										setIndex={setIndex}
										setShowCaution={setShowCaution}
										showCaution={showCaution}
									/>
								</div>
							))}
						</div>
						<Pagination
							totalNumberOfChunked={totalNumberOfChunked}
							setChunkIndex={setChunkIndex}
							chunkIndex={chunkIndex}
							chunkCards={chunkCards}
						/>
					</div>
				) : null}

				{chunkCards2.length > 1 ? (
					<div>
						<div className="pt-5 mt-5">
							<span
								className={`ml-[30px] xl:ml-[100px] mt-2 font-normal capitalize-first ${
									selectedProduct.toLowerCase() === "handmade beads"
										? "text-white"
										: "text-gray-800"
								}`}>
								beads & accessories
							</span>
						</div>
						<div className="grid justify-center w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
							{chunkCards2[chunkIndex2]?.map((product) => (
								<div
									key={product._id}
									className="flex flex-row items-center justify-center px-2 mb-2">
									<Card
										key={product._id}
										product={product}
										setSingleproduct={setSingleProduct}
										scrollToTop={scrollToTop}
										setIndex={setIndex}
										setShowCaution={setShowCaution}
										showCaution={showCaution}
									/>
								</div>
							))}
						</div>
						<Pagination2
							totalNumberOfChunked={totalNumberOfChunked2}
							setChunkIndex={setChunkIndex2}
							chunkIndex={chunkIndex2}
							chunkCards={chunkCards2}
						/>
					</div>
				) : null}
			</div>
		) : (
			<div className="border-b-[1px] mt-20 md:mt-10 border-b-neutral-300 pb-5">
				<div className="text-white ml-[30px] xl:ml-[100px] font-normal capitalize-first">
					<span>Filters</span>
				</div>
				<div className="grid justify-center w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
					{filterData?.map((product) => (
						<div
							key={product._id}
							className="flex flex-row items-center justify-center px-2 mb-2">
							<Card
								key={product._id}
								product={product}
								setSingleproduct={setSingleProduct}
								scrollToTop={scrollToTop}
								setIndex={setIndex}
								setShowCaution={setShowCaution}
								showCaution={showCaution}
							/>
						</div>
					))}
				</div>
				{globalFilter.length > contentPerPage && (
					<Pagination
						totalNumberOfChunked={totalNumberOfFilterChunked()}
						setChunkIndex={setFilterIndex}
						chunkIndex={filterIndex}
						chunkCards={globalFilter}
					/>
				)}
			</div>
		);
	};

	const FilteredByProduct = () => {
		return (
			selectedProduct !== "Filter Products by Categories" &&
			(selectedProduct === "Hair & Extensions" ? (
				<div className="pb-5 mt-16 md:mt-10">
					{data?.allHair.length > 1 && (
						<div className="text-white ml-[30px] xl:ml-[100px] font-normal capitalize-first">
							<span>hair and extensions</span>
						</div>
					)}
					<div className="grid justify-center w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
						{data?.allHair.map((product) => (
							<div
								key={product._id}
								className="flex flex-row items-center justify-center px-2 mb-2">
								<Card
									key={product._id}
									product={product}
									setSingleproduct={setSingleProduct}
									scrollToTop={scrollToTop}
									setIndex={setIndex}
									setShowCaution={setShowCaution}
									showCaution={showCaution}
								/>
							</div>
						))}
					</div>
				</div>
			) : (
				<div>
					{data?.allBeads.length > 1 && (
						<div
							className={`ml-[30px] xl:ml-[100px] font-normal capitalize-first ${
								selectedProduct.toLowerCase() === "handmade beads"
									? "text-white md:mt-10 mt-16"
									: "text-gray-800"
							}`}>
							<span>beads & accessories</span>
						</div>
					)}
					<div className="grid justify-center w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
						{data?.allBeads.map((product) => (
							<div
								key={product._id}
								className="flex flex-row items-center justify-center px-2 mb-2">
								<Card
									key={product._id}
									product={product}
									setSingleproduct={setSingleProduct}
									scrollToTop={scrollToTop}
									setIndex={setIndex}
									setShowCaution={setShowCaution}
									showCaution={showCaution}
								/>
							</div>
						))}
					</div>
				</div>
			))
		);
	};

	window.addEventListener("scroll", () => {
		if (
			window.innerHeight + window.scrollY >=
			document.body.offsetHeight - (window.innerHeight + window.scrollY)
		) {
			setShowTopBtn(true);
		} else {
			setShowTopBtn(false);
		}
	});

	async function onSubmit(values, { resetForm }) {
		if (!values.picked) setIsPicked(true);
		if (values.picked === "hair") {
			const filteredBeads = data.allHair.filter((hair) => {
				if (hair.name.toLowerCase().includes(values.query.toLowerCase()))
					return hair;
			});
			setGlobalFilter(filteredBeads);
		}
		if (values.picked === "beads") {
			const filteredBeads = data.allBeads.filter((bead) => {
				if (bead.name.toLowerCase().includes(values.query.toLowerCase()))
					return bead;
			});
			setGlobalFilter(filteredBeads);
		}
		resetForm();
	}

	const SearchSchema = Yup.object().shape({
		query: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
	});

	function getInputErrorClass(isErrorState) {
		return isErrorState ? "placeholder:text-orange-500 bg-orange-500/30" : "";
	}

	function getLabelClassColor(isErrorState) {
		return isErrorState ? "text-orange-500" : "text-white";
	}
	function getRadioBorderClass(isErrorState) {
		return isErrorState
			? "bg-orange-500 border-orange-500"
			: "bg-transparent border-neutral-500";
	}

	return (
		<>
			<Helmet>
				<title>PVG</title>
			</Helmet>

			<Layout>
				<div className="relative w-full">
					{banner && (
						<div
							className={`sticky-position flex items-center justify-between px-10 text-neutral-900 w-fit h-fit py-5 bg-blur3 z-50 md:mt-[-50px] rounded-lg capitalize-first`}>
							<p className="flex-[0.8]">
								thank you for choosing PVG internationals
							</p>
							<div
								onClick={() => closeBanner(false)}
								className=" w-fit h-fit flex-[0.2]">
								<span className="w-10 h-10 border-[1px] border-neutral-900 text-neutral-900 ml-10 p-2 rounded-full hover:text-neutral-100 hover:border-neutral-100 ease duration-300 hover:cursor-pointer flex items-center justify-center ">
									X
								</span>
							</div>
						</div>
					)}
					{showTopBtn && (
						<div>
							<BiUpArrowAlt
								onClick={goToTop}
								className="scrollToTopBtn z-100 absolute bottom-[-100px] right-0"
							/>
						</div>
					)}
					<div className="bg-neutral-900 p-5 rounded-[30px] h-[300px] sm:h-[350px]">
						<div className="flex flex-row justify-between items-center bg-neutral-700 h-[60px] px-2 rounded-lg">
							<span className="text-xs mt-5 md:mt-0 md:flex-[0.45] text-white mb-5 pl-2 md:mb-0">
								Home<span className="font-bold text-black"> / Shop</span>
							</span>
							<div className="md:flex-[0.55] md:flex md:justify-between">
								<div className="hidden md:inline">
									<span className="text-xl font-bold text-gray-400 mr-28 md:mr-0">
										PVG
										<span className="text-xs font-normal">
											{" "}
											International-s
										</span>
									</span>
								</div>
								<div className="flex flex-row items-center justify-between w-[130px] mr-2">
									<div
										onClick={() => setSelectedProduct("")}
										className={`flex flex-col items-center hover:cursor-pointer navStyleChild ease-in duration-300 hover:opacity-[0.5] ${
											selectedProduct === "" ? "text-yellow-500" : "text-white"
										}`}>
										<GrProductHunt
											size={30}
											color="white"
											className={`${
												selectedProduct === "" &&
												"border-[1px] border-yellow-500 rounded-full"
											}`}
										/>
										<span className="text-[10px]">All</span>
									</div>
									{filterList.map((item) => (
										<div
											key={item.id}
											onClick={() => handlePickProduct(item?.filterName)}
											className={`flex justify-center flex-col items-center hover:cursor-pointer hover:opacity-[0.5] ease-in duration-300 navStyleChild ${
												selectedProduct.toLowerCase() ===
												item?.filterName.toLowerCase()
													? "text-yellow-500"
													: "text-white"
											}`}>
											<img
												id={item.id}
												src={item?.src}
												alt={item.id}
												className={`w-[30px] h-[30px] rounded-full ${
													selectedProduct.toLowerCase() ===
														item?.filterName.toLowerCase() &&
													"border-[1px] border-yellow-500"
												}`}
											/>
											<span className="text-[10px] ease-in duration-300">
												{item.name}
											</span>
										</div>
									))}
								</div>
							</div>
						</div>
						<Formik
							initialValues={{
								query: "",
								picked: "",
							}}
							validationSchema={SearchSchema}
							onSubmit={onSubmit}>
							{({ errors, touched }) => (
								<Form className="flex flex-col pb-1 mt-4 text-sm font-thin text-white border-b xs:pb-5 xs:items-center xs:flex-row border-neutral-700/50">
									<Field
										id="query"
										name="query"
										placeholder="Search by product name ..."
										className={`px-3 py-3 placeholder:italic placeholder:text-neutral-400 placeholder:text-xs w-full sm:w-[50%] lg:w-[30%] xs:mr-5 xs:rounded-full rounded-r-full border-none focus:outline-none focus:border-gray-900 focus:ring-1 focus:ring-gray-900 text-black ${getInputErrorClass(
											errors.query
										)}`}
									/>
									<div className="flex items-center my-5">
										<div
											className="flex items-center mr-5"
											role="group"
											aria-labelledby="my-radio-group">
											<label
												className={`flex flex-row items-center mr-5 ${getLabelClassColor(
													isPicked
												)}`}>
												Hair
												<Field
													className={`ml-2 border ${getRadioBorderClass(
														isPicked
													)}`}
													type="radio"
													name="picked"
													value="hair"
												/>
											</label>
											<label
												className={`${getLabelClassColor(
													isPicked
												)} flex flex-row items-center`}>
												Beads
												<Field
													className={`ml-2 border ${getRadioBorderClass(
														isPicked
													)}`}
													type="radio"
													name="picked"
													value="beads"
												/>
											</label>
										</div>

										{!globalFilter.length ? (
											<button
												className="flex items-center text-blue-500"
												type="submit">
												Search
												<MdKeyboardArrowRight size={25} />
											</button>
										) : (
											<div
												className="flex items-center font-normal cursor-pointer text-blue-500 h-[25px]"
												onClick={() => setGlobalFilter([])}>
												Reset
												<VscClose size={20} className="ml-1 text-blue-500" />
											</div>
										)}
									</div>
								</Form>
							)}
						</Formik>
					</div>
					<div
						className={`mt-[-100px] ${
							singleProduct._id ? "sm:mt-[-160px]" : "sm:mt-[-230px]"
						} w-full pb-1`}>
						{singleProduct._id && (
							<div>
								<ProductDetails
									index={index}
									setIndex={setIndex}
									singleProduct={singleProduct}
									setSingleProduct={setSingleProduct}
									setShowCaution={setShowCaution}
									showCaution={showCaution}
								/>
							</div>
						)}
						{data?.allBeads && data?.allHair && prod ? (
							selectedProduct !== "" ? (
								<FilteredByProduct />
							) : (
								<Beauties />
							)
						) : (
							<div className="mt-10 rounded-full progress">
								<div className="inner"></div>
							</div>
						)}
					</div>
					<div className="sticky bottom-0 z-20 w-[100%]">
						<div className="flex flex-row justify-center text-center w-[100%] h-[50px]">
							<p
								className="font-light text-green-700 text-sm bg-neutral-800 border-t-[1px] border-green-900 w-full flex items-center justify-center"
								onClick={() => setShowWhatsapp(!showWhatsapp)}>
								{showWhatsapp
									? "Hide whatsapp button"
									: "Contact us via whatsapp, "}
								<strong className="ml-1 text-yellow-500">click...</strong>
							</p>
							{showWhatsapp && (
								<div>
									<CtaButton isIcon={true} />
								</div>
							)}
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

export default Home;
